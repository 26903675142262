import React from "react";
import Markdown from "react-markdown";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const Home = ({ data }) => {
    return (
        <>
            <div className="container">
                <div className="p-3">
                    <div className="row">
                        <div className="col-lg-5 crop-height p-lg-5 m-lg-auto">
                            <img src={data.pic_intro.publicURL} className="scale" alt="Group People" />
                        </div>

                        <div className="col-lg-6 pt-3 pr-3">
                            <div className="a1">{data.title}</div>
                            <div className="a2">{data.subtitle}</div>
                            <div className="gSeparator"></div>
                            <Markdown source={data.introduction} className="py-2"></Markdown>
                            <button className="aButton float-right"><a href="mailto:info@gearmission.com">Contact Us</a></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-5" style={{ backgroundColor: '#F1F3F4' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="a1 text-center">{data.expertise_title}</div>
                        <p className="text-center">{data.expertise_description}</p>
                    </div>
                </div>
                {/* <div className="row separatorDown px-4">
                    {data.expertise.map((item, index) =>
                        <div className="col-sm-6 col-lg-3 pt-5 pb-4" key={index}>
                            <div className="crop-height home">
                                <img src={item.picture.publicURL} className="scale" alt="Group People" />
                            </div>
                            <div className="row">
                                <div className="col-md-12 pt-3">
                                    <h3>{item.title}</h3>
                                    <p>{item.descrition}</p>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div> */}

                <div className="row separatorDown px-4">
                    {data.expertise.map((item, index) =>
                        <div className="col-sm-6 col-lg-3 pt-5 pb-4" key={index}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        className="cards"
                                        image={item.picture.publicURL}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">{item.title}</Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">{item.descrition}</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
};
export default Home;