import React from 'react';

const Hexagon = ({ data }) => {
    return (
        <ul id="hexGrid">
            {data.map((item, index) =>
                <li className="hex" key={index}>
                    <div className="hexIn">
                        <a className="hexLink">
                            <img src={item.picture.publicURL} alt="" />
                            {/* <h1>This is a title</h1> */}
                            <p>{item.title}</p>
                        </a>
                    </div>
                </li>
            )}

        </ul>
    )
}

export default Hexagon;