import React from 'react';

const Jumbotron = ({ data }) => {

    return (
        <>
            <div className="jumbotron jumbotron-fluid" style={{ backgroundImage: `url(${data.background_image.publicURL})` }}>
                <div className="container">
                    {/* <h1 className="display-4 pb-4"><span className="jumbotronBackground">{data.title}</span></h1> */}
                    <h1 className="display-4 mt-4"><span className="jumbotronBackground"><img src={data.logo.publicURL} width="300em" /></span></h1>
                    <hr className="my-4" />
                    <p className="p2"><span className="jumbotronBackground">{data.subtitle}</span></p>
                    {/* <p className="lead">
                        <button className="aButton">Learn more</button>
                    </p> */}
                </div>
            </div>
        </>
    )
}

export default Jumbotron;