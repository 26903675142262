import React from "react";
import Hexagon from './hexagon';


const Applications = ({ data }) => {

    return (
        <div className="container separatorUp">
            <div className="row pt-5">
                <div className="col-md-12 gPadding">
                    <div className="a1 text-center">{data.title}</div>
                    <div className="text-center">{data.subtitle}</div>
                    {/* <hr></hr> */}
                </div>
            </div>
            <div className="row separatorUp">
                <div className="col-md-12 gPadding">
                    <Hexagon data={data.areas}></Hexagon>
                </div>
            </div>
        </div >
    );
};

export default Applications;
