import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Jumbotron from "../components/jumbotron";
import Home from "../components/home";
import Applications from "../components/applications";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      <Jumbotron data={data.strapiJumbotron} />
      <Home data={data.strapiHomepage} />
      <Applications data={data.strapiApplications} />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiJumbotron {
      background_image {
        publicURL
      }
      logo {
        publicURL
      }
      subtitle
      title
    }
    strapiHomepage {
      pic_intro {
        publicURL
      }
      subtitle
      title
      introduction
      expertise_title
      expertise_description
      expertise {
        title
        descrition
        url
        picture {
          publicURL
        }
      }
    }
    strapiApplications {
        title
        subtitle
        areas {
          title
          url
          picture {
            publicURL
          }
        }
    }    
  }
`;

export default IndexPage;
